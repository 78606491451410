@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom particle animation styles */
.particles-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(77, 171, 247, 0.5);
  border-radius: 50%;
  animation: float 6s infinite ease-in-out;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-100px) translateX(50px);
    opacity: 1;
  }
}